
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class ThresholdDropdown extends Vue {
  @PropSync("selected", { default: () => 480, type: Number })
  selectedSync!: number;

  /**
   * Value in minutes
   */
  options = [
    // { text: "1 Minute", value: 1 },
    // { text: "5 Minutes", value: 5 },
    // { text: "15 Minutes", value: 15 },
    // { text: "30 Minutes", value: 30 },
    // { text: "1 Hour", value: 60 },
    // { text: "2 Hours", value: 120 },
    // { text: "4 Hours", value: 240 },
    { text: "8 Hours", value: 480 },
    { text: "1 Day", value: 1440 },
    { text: "3 Days", value: (1440 * 3) },
    { text: "7 Days", value: (1440 * 7) },
    { text: "30 Days", value: (1440 * 30) },
  ];

  @Watch("selected")
  onSelectChange() {
    // console.log("B", this.selectedSync);
    this.$emit("select", this.selectedSync);
  }
}
