
import { DateFormatMixin } from "@/mixins";
import { MR } from "@/store/modules";
import { PositionModule } from '@/store/modules/dynamic-modules';
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { Observable, from } from "rxjs";
import { filter, map, switchMap, toArray } from "rxjs/operators";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AddressMap, { MapAddress } from "./AddressMap.vue";

const env = process.env;
mapboxgl.accessToken = `${env.VUE_APP_MAP_BOX}`;

@Component({ components: { AddressMap } })
export default class MRMap extends mixins(DateFormatMixin) {
  errorMessage: string | null = null;

  loading = true;

  currentPosition?: LngLatLike;

  @Prop({ default: () => [49, -122], type: [Array] })
  defaultPosition!: [number, number] | null;

  addresses: MapAddress[] = [];

  async mounted() {
    if (!("geolocation" in navigator)) {
      this.errorMessage = "Geolocation is not available.";
      return;
    }

    this.$nextTick(() => this.initData());
    // this.$nextTick(() => this.updateData());
  }

  async initData() {
    this.loading = true;
    await this.updateCurrentLocation();

    const addresses = await this.pullAddresses().toPromise();
    this.addresses = addresses;
    this.loading = false;
  }

  async updateCurrentLocation() {
    const pos = await this.getCurrentLocation();
    if (pos) {
      this.currentPosition = pos;
      return;
    }
  }

  async getCurrentLocation(): Promise<LngLatLike | void> {
    await PositionModule.getCurrentPosition();
    return this.$store.state.positionModule.currentPosition;
    // if (PositionModule.position) {
    //   return PositionModule.position;
    // }
  }

  getCenterLatLong(): LngLatLike | null {
    if (!this.currentPosition) {
      return this.defaultPosition;
    }
    return this.currentPosition;
    // return [coords.longitude, coords.latitude];
  }

  pullAddresses() {
    const source$ = from(
      new Promise<void>((ok, fail) => {
        MR.dispatch("fetchData", {
          filter: {
            // approved: 1,
            // voided: 0
            // paid: 1
          }
        })
          .then(() => ok())
          .catch(fail);
      })
    );

    return source$.pipe(
      switchMap<void, Observable<MR>>(() => {
        const records = MR.all();
        return from(records);
      }),
      filter(mr => !!mr.address_data),
      //
      map<MR, MapAddress>(mr => ({
        address: mr.address_data || "",
        enablePopup: true,
        popupHtml: [
          `<strong><a href="#/mr/${mr.id}">MR #${mr.taskNumber}</a></strong>`,
          `<p class="mb-0">${mr.customer_name}</p>`,
          `<p>${mr.address_data}</p>`
        ].join("")
      })),
      toArray()
    );

    // const records = MR.all();
    // const records$ = from(records);
    // return records$.pipe(
    //   filter(mr => !!mr.address_data),
    //   map<MR, string>(mr => mr.address_data || '')
    // )
    // MR.all().map<string>(());
  }
  /*async dispatchMr() {
    return await MR.dispatch('fetchData', {
      filter: {
        approved: 1,
        voided: 0
        // paid: 1
      }
    });
  }*/
}
