
import { Component, Vue, Prop } from "vue-property-decorator";
import mapboxgl, { Map, LngLatLike, Marker } from "mapbox-gl";
import {
  MapBoxApiGeolocationPayloadFeature,
  MapBoxApiGeolocationPayload
} from "@/utility";
// import {from} from 'rxjs';
// import MapiClient from '@mapbox/mapbox-sdk';

// console.log(mapboxSdk);
// const mbxClient = require('@mapbox/mapbox-sdk');

export interface MapAddress {
  address: string;
  enablePopup: boolean;
  popupHtml?: string;
}

export interface AddressProcessPayload {
  address: MapAddress;
  geo: MapBoxApiGeolocationPayload | null;
}

@Component({})
export default class AddressMap extends Vue {
  @Prop({ default: () => [], type: Array })
  addresses!: MapAddress[];

  @Prop([Array, Object])
  center?: LngLatLike;

  // @Prop({default: () => []})
  // locationMarkers!: Collection<GeoLocation>;

   @Prop({ default: 11, type: Number })
  zoom!: number;

  map?: Map;

  mounted() {
    // console.log("mounted", this.$el);
    this.initMap();
  }

  beforeDestroy() {
    // console.log("beforeDestroy");
    this.destroyMap();
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: this.$el as HTMLElement,
      style: "mapbox://styles/mapbox/light-v10",
      zoom: this.zoom
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on("load", () => this.mapLoaded());
    this.map.resize();

    if (this.center) {
      this.map.setCenter(this.center);
    }
  }

  destroyMap() {
    if (!this.map) {
      return;
    }
    // stop all transactions
    this.map.stop();
    this.map.off("load", () => this.map);
    // explictly call remove to remove workers
    this.map.remove();
    this.map = undefined;
  }

  mapLoaded() {
    console.warn("mapLoaded", this.addresses);
    if (this.addresses.length) {
      // this.drawMarkers(this.locationMarkers);
      this.$nextTick(() => this.drawMarkers());
    }
  }

  async drawMarkers() {
    if (!this.map) {
      console.error("Missing map instance");
      return;
    }
    const addresses = await this.processAddress();
    addresses
      .map(data => this.createMarker(data))
      .forEach(marker => this.map && marker && marker.addTo(this.map));
  }

  async processAddress(): Promise<Array<AddressProcessPayload | null>> {
    // const client = mbxClient({
    //   accessToken: process.env.VUE_APP_MAP_BOX
    // });
    // const endpoint = 'mapbox.places-permanent';
    const endpoint = "mapbox.places";
    const token = `${process.env.VUE_APP_MAP_BOX}`;
    const baseUrl = "https://api.mapbox.com/geocoding/v5";
    const storage = localStorage;
    const addresses = this.addresses;
    return await Promise.all(
      addresses.map(async address => {
        const url = new URL(
          `${baseUrl}/${endpoint}/${address.address}.json`
        );
        url.searchParams.append("access_token", `${token}`);
        /*const cache = storage.getItem(address);
      if (cache) {
        return JSON.parse(cache);
      }*/

        const response = await fetch(`${url}`);
        if (response.status !== 200) {
          return null;
        }

        const data = await response.json();

        if ("features" in data && data.features.length) {
          return { address, geo: data };
        }

        return null;
      })
    );
    // Object.keys(params).forEach(key => url.searchParams.append(key, ''+params[key]))
  }

  createMarker(data: AddressProcessPayload | null) {
    if (!data) {
      return null;
    }
    const { address, geo } = data;

    if (!geo || !geo.features || !geo.features.length) {
      return null;
    }

    const marker = new mapboxgl.Marker({
      color: "#aa1212"
    });
    const feature = geo.features[0];
    // console.log('creating', [location.long, location.lat]);
    marker.setLngLat(feature.center);
    if (address.enablePopup) {
      const popup = new mapboxgl.Popup({ offset: 10 });
      if(address.popupHtml) {
        popup.setHTML(address.popupHtml);
      } else {
        popup.setHTML(feature.place_name);
      }

      marker.setPopup(popup);
    }

    return marker;
  }
}
